import * as React from 'react';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Row, Col, Card, Space, Button, ListGroup, Loader } from 'ebs-design';
import { useDashboardAPI } from 'api';
import { Funnel } from 'components/molecules';
import { getColor } from 'components/molecules/Charts/utils';
import { SalesReport, DashboardProps } from 'types';
import { useRolePermission } from 'hooks/useRolePermission';
import { UserRoleCRM } from 'hooks/useFetcher/interfaces';

export const SalesFunnel: React.FC<DashboardProps> = ({ ...params }) => {
  const { t } = useTranslation();

  const { getSalesReport } = useDashboardAPI();

  const { data = {}, isLoading } = useQuery<SalesReport>(['sales-report', params], getSalesReport);

  const keysSalesReport = React.useMemo(() => Object.keys(data), [data]);
  const chartData = React.useMemo(
    () =>
      keysSalesReport.map((key, i) => ({
        text: t(`reports.${key}`),
        value: data[key],
        color: getColor(i),
      })),
    [t, data],
  );

  return (
    <Card>
      <Card.Header>{t('menu.sales_funnel')}</Card.Header>
      <Card.Body>
        <Loader loading={isLoading}>
          <Row>
            <Col size={6}>
              {!isLoading && <Funnel redrawOnResize sortData={false} dataSource={chartData} />}
            </Col>
            <Col size={6}>
              <ListGroup className="bg-transparent no-border">
                {chartData.map(({ text, value, color }, key) => (
                  <ListGroup.Item key={`list-item-${key}`}>
                    <Space justify="space-between">
                      <Space size="small" className="p-7">
                        <div className="reports-point" style={{ background: color }} />
                        <b>{text}</b>
                      </Space>
                      {value}
                    </Space>
                  </ListGroup.Item>
                ))}
              </ListGroup>
            </Col>
          </Row>
        </Loader>
      </Card.Body>
      <Card.Footer>
        <Space justify="space-between">
          <Space size="small">
            <>{t('reports.total')}:</>

            {!isLoading ? keysSalesReport.length : <Loader.Inline />}
          </Space>

          {useRolePermission([
            UserRoleCRM.marketing,
            UserRoleCRM.manager,
            UserRoleCRM.admin,
            UserRoleCRM.expert,
            UserRoleCRM.internal_auditor,
            UserRoleCRM.collector,
          ]) && (
            <Link to="/sales-funnel-kanban">
              <Button type="primary">{t('reports.viewAll')}</Button>
            </Link>
          )}
        </Space>
      </Card.Footer>
    </Card>
  );
};
