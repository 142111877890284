import * as React from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { Actions, AvatarInline, Button, ButtonGroup, Icon, Space, Tooltip } from 'ebs-design';
import { SessionContext } from 'contexts/SessionContext';
import { SmartSelect } from 'components/organisms';
import { useDomain } from 'hooks/useDomain';
import { Arrow, Email, Phone } from 'resources/icons';
import { CallCategoryType, Contact, Lead } from 'types';
import { ServiceTypes, UserRoleCRM } from 'hooks/useFetcher/interfaces';
import { useRolePermission } from 'hooks/useRolePermission';
import { useExpertPermission } from 'hooks/useExpertPermission';

interface ContactItemProps {
  contact: Contact;
  onCall: (data) => void;
  onEdit: (id) => void;
  onMail: (id) => void;
  onDelete: (id) => void;
  lead: Lead;
}

export const ContactItem: React.FC<ContactItemProps> = ({
  contact,
  onCall,
  onEdit,
  // onMail,
  onDelete,
  lead,
}) => {
  const { t } = useTranslation();
  const { active, getUrl } = useDomain();
  const [collapsed, setCollapsed] = React.useState(false);
  const { session } = React.useContext(SessionContext);

  const handleToggleCollapsed = (): void => {
    setCollapsed((s) => !s);
  };

  const mailToken = React.useMemo(
    () =>
      session?.data?.pinned_applications?.find(
        ({ instance_type: { type } }) => type === ServiceTypes.MAIL.toLowerCase(),
      )?.token || null,
    [session],
  );

  const mailUrl = active && getUrl(active);

  return (
    <div className="contact-item" onDoubleClick={() => onEdit(contact.id)}>
      <Space justify="space-between" align="start">
        <div>
          <div className="contact-item__contact-label">{t('contacts.contact')}</div>
          <AvatarInline
            className="mt-5 mb-5"
            type="dynamic"
            shortAlt={[(contact?.first_name || '')[0], (contact?.last_name || '')[0]].join('')}
            alt={[contact?.first_name, contact?.last_name].join(' ') || '---'}
            circle
          />
        </div>
        {useRolePermission([UserRoleCRM.marketing, UserRoleCRM.manager, UserRoleCRM.admin]) ||
        useExpertPermission({ ownerId: lead.owner?.id }) ? (
          <Actions title={t('opportunities.action')} placement="right">
            <Actions.Item onClick={() => onEdit(contact?.id)}>
              {t('opportunities.edit')}
            </Actions.Item>
            <Actions.Item onClick={() => onDelete(contact?.id)}>
              {t('opportunities.delete')}
            </Actions.Item>
          </Actions>
        ) : null}
      </Space>

      {contact?.job_title && (
        <>
          <div className="contact-item__contact-label">{t('contacts.jobTitle')}</div>
          <div className="contact-item__contact">{contact.job_title}</div>
        </>
      )}

      {collapsed && (
        <>
          {contact?.email && (
            <>
              <div className="contact-item__contact-label">{t('contacts.email')}</div>
              <div className="contact-item__contact">{contact.email}</div>
            </>
          )}

          {contact?.phone_number && (
            <>
              <div className="contact-item__contact-label">{t('contacts.phone')}</div>
              <div className="contact-item__contact">{contact.phone_number}</div>
            </>
          )}

          {contact?.landline_number && (
            <>
              <div className="contact-item__contact-label">{t('contacts.phone')}</div>
              <div className="contact-item__contact">{contact.landline_number}</div>
            </>
          )}
          {contact?.social_url && (
            <>
              <div className="contact-item__contact-label">{t('contacts.socialUrl')}</div>
              <div className="contact-item__contact">
                <a href={contact.social_url} target="_blank" rel="noreferrer">
                  {contact.social_url}
                </a>
              </div>
            </>
          )}
        </>
      )}
      <div className="contact-item__footer">
        <Space className="contact-item__footer-box" justify="end">
          <Space size={10}>
            <ButtonGroup className="contact-item__button-group">
              {useRolePermission([
                UserRoleCRM.admin,
                UserRoleCRM.expert,
                UserRoleCRM.manager,
                UserRoleCRM.marketing,
              ]) && (
                <a
                  // eslint-disable-next-line prettier/prettier
                  href={
                    mailToken
                      ? `${mailUrl}/apps/mail/${mailToken}/${ServiceTypes.MAIL.toLowerCase()}/compose/?to=${
                          contact.email
                        }`
                      : `mailto:${contact.email}`
                  }
                  target="_blank"
                  rel="noreferrer"
                >
                  <Button
                    className="contact-item__button-group__button"
                    // onClick={() => onMail(contact.email)}
                    prefix={<Email />}
                  />
                </a>
              )}

              {useRolePermission([
                UserRoleCRM.admin,
                UserRoleCRM.expert,
                UserRoleCRM.manager,
                UserRoleCRM.marketing,
                UserRoleCRM.collector,
                UserRoleCRM.soft_collector,
              ]) && (
                <Tooltip
                  placement="bottom"
                  bodyClass="p-0 lead-details__tooltip"
                  trigger="click"
                  tooltip={
                    <SmartSelect
                      options={Object.keys(CallCategoryType).map((type) => ({
                        value: type,
                        text: t(`calls.${type}`),
                      }))}
                      optionsMode="box"
                      onChange={(value) => onCall({ id: contact.id, category: value })}
                    />
                  }
                >
                  <Button className="opportunity-item__button-group__button" prefix={<Phone />} />
                </Tooltip>
              )}
            </ButtonGroup>

            <Button
              className={cn('contact-item__collapse-button', { collapsed })}
              type="ghost"
              onClick={handleToggleCollapsed}
              prefix={<Icon className="contact-item__collapse-icon" component={Arrow} />}
            />
          </Space>
        </Space>
      </div>
    </div>
  );
};
