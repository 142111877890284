import useFetcher from 'hooks/useFetcher/useFetcher';
import { ServiceTypes } from 'hooks/useFetcher/interfaces';
import { stringifyUrl, getRequest } from 'utils';
import { Properties } from 'types';

export const useLeadHandlersAPI = (): Properties => {
  const fetcher = useFetcher(ServiceTypes.CRM);

  return {
    getLeadsList: ({ queryKey: [, query] }) => {
      return fetcher(stringifyUrl(`/lead-handlers`, query), getRequest);
    },

    getLeadById: ({ queryKey: [, query] }) => {
      return fetcher(
        stringifyUrl(`/lead-handlers/${query?.id}`, { branch: query?.branch }),
        getRequest,
      );
    },

    postLead: (data) => {
      return fetcher('/lead-handlers', {
        data,
        request: {
          method: 'POST',
        },
      });
    },

    patchLead: ({ id, ...data }) => {
      return fetcher(`/lead-handlers/${id}`, {
        data,
        request: {
          method: 'PATCH',
        },
      });
    },

    deleteLead: (id: number) => {
      return fetcher(`/lead-handlers/${id}`, {
        request: {
          method: 'DELETE',
        },
      });
    },
  };
};
