import { Agent } from './agents';

export interface LeadHandlersType {
  id: number;
  user: Agent;
  modified_at: string;
  created_at: string;
  is_active: boolean;
  allocated_leads: number;
  is_main_handler: boolean;
}

export enum LeadActiveStatus {
  true = 'success',
  false = 'danger',
}
