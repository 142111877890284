export enum ServiceTypes {
  SSO = 'SSO',
  CMS = 'CMS',
  CRM = 'CRM',
  FS = 'FS',
  ORGANIZATION = 'ORGANIZATION',
  MONITORING = 'MONITORING',
  ROCKET_APPS_DESIGNER = 'ROCKET APPS DESIGNER',
  ROCKET_APPS_EXECUTION = 'ROCKET APPS EXECUTION',
  SMART_PROCESS = 'SMART PROCESS',
  CALENDAR = 'CALENDAR',
  MAIL = 'MAIL',
  CONNECT = 'CONNECT',
  AUTH = 'AUTH',
}

export interface ServiceConfig {
  /**
   * The base url of the service.
   */
  baseUrl: string;

  /**
   * The Saas application token
   */
  token?: string;
}

export enum StatusTypes {
  PENDING,
  RESOLVED,
  REJECTED,
}

export interface Tokens {
  /**
   * This holds the access tokens, use it
   * for any API request.
   *
   * If the API returns invalid token error,
   * then use the refresh token to get a new `access` token.
   */
  access: string;

  /**
   * This is the refresh token.
   *
   * Only use it after the API has returned an invalid token error.
   */
  refresh: string;
}

export enum UserRoleCRM {
  expert = 'Expert',
  manager = 'Manager',
  marketing = 'Marketing',
  admin = 'Admin',
  collector = 'Collector',
  internal_auditor = 'Internal Auditor',
  soft_collector = 'Soft Collector',
}

export interface SessionData {
  id: number;
  user: any;
  role: string;
  organization: any;
  available_applications: { [key: string]: any };
  available_organizations: any[];
  pinned_applications: any[];
  current_application: {
    application_role: UserRoleCRM;
    type: string;
    name: string;
    token: string;
  } | null;
}

export interface Session {
  data: SessionData | null;
  tokens: Tokens;
}
