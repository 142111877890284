import * as React from 'react';
import cn from 'classnames';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
  Actions,
  Button,
  Card,
  DatePicker,
  Icon,
  Label,
  Loader,
  Modal,
  Space,
  Table,
  Textarea,
  Tooltip,
  useNotify,
  useForm,
  Form,
} from 'ebs-design';
import { useQueryParams, useQueryUpdate } from 'hooks/useQueryParams';
import { OpportunityContextProvider } from 'contexts/OpportunityContext';
import { LoaderContext } from 'contexts/LoaderContext';
import { Pagination, TaskForm } from 'components/organisms';
import { ConfirmModal } from 'components/molecules';
import { CompleteTask } from 'features/leads/DetailsCol/Cards/TaskCard/CompleteTaks';
import { TaskFilter } from '../Filters/TaskFilter';
import { Check, Plus } from 'resources/icons';
import { useTaskAPI } from 'api';
import {
  dateFormat,
  dateFormatAPI,
  dateTimeFormat,
  dateTimeFormatInput,
  format,
  isFeature,
  today,
  toISO,
  getAPIDateFormat,
} from 'libs';
import { defaultFilters, labelStatus, labelText } from 'utils';
import { Code, Column, FilterType, OutstandingType, Results, Task } from 'types';
import { useRolePermission } from 'hooks/useRolePermission';
import { UserRoleCRM } from 'hooks/useFetcher/interfaces';

const formId = 'task-form';

export const Tasks: React.FC = () => {
  const { t } = useTranslation();
  const [form] = useForm();
  const params = useQueryParams();
  const notify = useNotify();
  const queryClient = useQueryClient();
  const { getTasks } = useTaskAPI();
  const { updateQuery } = useQueryUpdate();
  const { patchTask, deleteTask } = useTaskAPI();
  const { loader } = React.useContext(LoaderContext);

  const [open, setOpen] = React.useState(false);
  const [editIdItem, setEditIdItem] = React.useState<number>();
  const [deleteIdItem, setDeleteIdItem] = React.useState();
  const [task, setTask] = React.useState<Task | undefined>();
  const [completeTaskItem, setCompleteTask] = React.useState<Task | undefined>();
  const [loadingId, setLoadingId] = React.useState<number>();

  const hasEditPermission = useRolePermission([
    UserRoleCRM.marketing,
    UserRoleCRM.manager,
    UserRoleCRM.admin,
    UserRoleCRM.expert,
    UserRoleCRM.soft_collector,
  ]);

  const { data: tasks, isLoading } = useQuery<Results<Task>>(
    [
      'tasks',
      {
        ...defaultFilters,
        ...params,
        completed: params.completed === 'all' ? undefined : params.completed || 0,
        deadline_start:
          params.deadline_start && format(params.deadline_start, dateFormatAPI, dateFormat),
        deadline_end: params.deadline_end && format(params.deadline_end, dateFormatAPI, dateFormat),
        deadline: undefined,
      },
    ],
    getTasks,
    {
      onError: () => {
        notify.error({ title: t('error.someThingIsWrong') });
      },
    },
  );

  React.useEffect(() => {
    if (task) {
      const { comment, deadline } = task;
      form.setFieldsValue({
        comment: comment,
        ...(Object.values(OutstandingType).includes(
          task?.type?.code as unknown as OutstandingType,
        ) && { deadline: deadline && format(deadline, dateTimeFormat) }),
      });
    }
  }, [task]);

  const mutation = useMutation(deleteTask, {
    onError: () =>
      notify.error({
        title: t('error.someThingIsWrong'),
      }),
    onSuccess: () => {
      queryClient.invalidateQueries(['tasks']);
      setDeleteIdItem(undefined);
      notify.success({ title: t('success.successDataDelete') });
    },
  });

  const mutationComplete = useMutation(
    (body: { id: number; completed_at?: string | null; comment: string; deadline?: string }) => {
      return patchTask(body);
    },
    {
      onError: () => notify.error({ title: t('error.someThingIsWrong') }),
      onSuccess: () => {
        setLoadingId(undefined);
        queryClient.invalidateQueries(['tasks']);
      },
    },
  );

  const handleFilter = (type: FilterType, field: string): FilterType => {
    updateQuery({ ...params, ordering: type ? `${type === 'desc' ? '-' : ''}${field}` : type });
    return type;
  };

  const handleCloseTaskForm = (): void => {
    setOpen(false);
    setEditIdItem(undefined);
  };

  const handleAdd = (): void => {
    setOpen(true);
  };

  const handleEdit = (id: number): void => {
    setEditIdItem(id);
  };

  const handleDelete = (): void => {
    if (deleteIdItem) {
      mutation.mutate(deleteIdItem);
    }
  };

  const handleCompleteTask = (task: Task): void => {
    if (!Object.values(Code).includes(task?.type?.code) || task?.type?.code === Code.GENERIC) {
      completeTask(task);
    } else {
      if (!task.completed_at) {
        setCompleteTask(task);
      }
    }
  };

  const completeTask = (task: Task): void => {
    setLoadingId(task.id);
    mutationComplete.mutate({
      id: task.id,
      completed_at: task.completed_at ? null : toISO(today.toString()),
      comment: form.getFieldsValue().comment || task.comment,
      ...(form.getFieldsValue().deadline && {
        deadline: getAPIDateFormat(form.getFieldsValue().deadline),
      }),
    });
  };

  const columns: Column<Task>[] = React.useMemo(
    () => [
      ...(hasEditPermission
        ? [
            {
              render: (task) => (
                <Button
                  onClick={() => (task.completed_at ? handleCompleteTask(task) : setTask(task))}
                  prefix={<Icon component={Check} />}
                  loading={loadingId === task.id}
                  disabled={
                    task.completed_at &&
                    Object.values(Code).includes(task?.type?.code) &&
                    task?.type?.code !== Code.GENERIC
                  }
                  className={cn('task-table__confirm', { active: Boolean(task.completed_at) })}
                />
              ),
            },
          ]
        : []),
      {
        title: t('task.title'),
        onFilter: (type) => handleFilter(type, 'title'),
        render: ({ completed_at, title }) => (
          <div className={cn('task-table__confirm', { completed: Boolean(completed_at) })}>
            {title}
          </div>
        ),
      },
      {
        title: t('task.dateTime'),
        onFilter: (type) => handleFilter(type, 'deadline'),
        render: ({ completed_at, deadline }) => (
          <div className={cn('task-table__confirm', { completed: Boolean(completed_at) })}>
            {deadline && (
              <Label
                className="task-table__deadline-label"
                status={isFeature(deadline) ? 'info' : 'danger'}
                text={format(deadline, dateTimeFormat)}
              />
            )}
          </div>
        ),
      },
      {
        title: t('task.leadName'),
        onFilter: (type) => handleFilter(type, 'lead__name'),
        render: ({ completed_at, lead }) => (
          <Link to={`/lead/${lead?.id}`}>
            <div className={cn('task-table__confirm', { completed: Boolean(completed_at) })}>
              {lead?.name}
            </div>
          </Link>
        ),
      },
      {
        title: t('task.responsiblePerson'),
        onFilter: (type) => handleFilter(type, 'owner'),
        render: ({ completed_at, owner }) => (
          <div className={cn('task-table__confirm', { completed: Boolean(completed_at) })}>
            {[owner?.first_name, owner?.last_name].join(' ')}
          </div>
        ),
      },
      {
        title: t('task.taskAuthor'),
        onFilter: (type) => handleFilter(type, 'created_by'),
        render: ({ created_by, completed_at }) => (
          <div className={cn('task-table__confirm', { completed: Boolean(completed_at) })}>
            {created_by?.name}
          </div>
        ),
      },
      {
        title: t('task.shortDescription'),
        render: ({ completed_at, description }) => (
          <div className={cn('task-table__confirm', { completed: Boolean(completed_at) })}>
            <Tooltip
              placement="top"
              trigger="hover"
              tooltip={<span className="task-table__description__tooltip">{description}</span>}
            >
              <span className="no-wrap task-table__description">{description}</span>
            </Tooltip>
          </div>
        ),
      },
      {
        title: t('task.priority'),
        onFilter: (type) => handleFilter(type, 'priority'),
        render: ({ completed_at, priority }) =>
          priority && (
            <div className={cn('task-table__confirm', { completed: Boolean(completed_at) })}>
              <Label
                type="fill"
                className="task-table__priority-label"
                status={labelStatus[priority]}
                text={t(labelText[priority])}
              />
            </div>
          ),
      },
      ...(hasEditPermission
        ? ([
            {
              title: t('task.action'),
              align: 'right',
              render: (task): React.ReactNode =>
                (!Object.values(Code).includes(task?.type?.code) ||
                  task?.type?.code === Code.GENERIC) &&
                !task.completed_at && (
                  <Space justify="end">
                    <Actions>
                      <div className="table-actions">
                        <Actions.Item onClick={() => handleEdit(task.id)}>
                          {t('documents.edit')}
                        </Actions.Item>
                        <Actions.Item onClick={() => setDeleteIdItem(task.id)}>
                          {t('documents.delete')}
                        </Actions.Item>
                      </div>
                    </Actions>
                  </Space>
                ),
            },
          ] as Column<Task>[])
        : []),
    ],
    [t, loadingId, params],
  );

  const buttonLoading = React.useMemo(() => {
    if (formId && loader) {
      return loader[formId];
    }
    return false;
  }, [formId, loader]);

  const onCloseTask = React.useCallback(() => {
    if (task) {
      mutationComplete.mutate({
        id: task.id,
        comment: form.getFieldsValue().comment || task.comment,
      });
    }

    setTask(undefined);
  }, [task, mutationComplete]);

  return (
    <OpportunityContextProvider>
      <div className="task-table">
        {completeTaskItem && (
          <CompleteTask
            task={completeTaskItem}
            leadId={completeTaskItem.lead.id}
            onCancel={() => setCompleteTask(undefined)}
          />
        )}
        {(open || editIdItem) && (
          <Modal
            closeOnClickOutside={false}
            open
            header={t('task.task')}
            onClose={handleCloseTaskForm}
          >
            <Modal.Content>
              {<TaskForm formId={formId} id={editIdItem} closeModal={handleCloseTaskForm} />}
            </Modal.Content>
            <Modal.Footer>
              <Space justify="space-between">
                <Button onClick={handleCloseTaskForm}>{t('buttons.cancel')}</Button>
                <Button
                  type="primary"
                  submit
                  form={formId}
                  loading={buttonLoading}
                  prefix={<Icon className="action-card__save-button" component={Check} />}
                >
                  {t('buttons.save')}
                </Button>
              </Space>
            </Modal.Footer>
          </Modal>
        )}
        {deleteIdItem && (
          <ConfirmModal onSuccess={handleDelete} onCancel={() => setDeleteIdItem(undefined)} />
        )}
        {task &&
          (!Object.values(Code).includes(task?.type?.code) || task?.type?.code === Code.GENERIC ? (
            <Modal closeOnClickOutside={false} open header={t('task.task')} onClose={onCloseTask}>
              <Modal.Content>
                <Form form={form}>
                  <Form.Field name="comment">
                    <Textarea placeholder={t('contacts.comment')} />
                  </Form.Field>
                  {Object.values(OutstandingType).includes(
                    task?.type?.code as unknown as OutstandingType,
                  ) && (
                    <Form.Field name="deadline">
                      <DatePicker showTimeSelect dateFormat={dateTimeFormatInput} />
                    </Form.Field>
                  )}
                </Form>
              </Modal.Content>
              <Modal.Footer>
                <Space justify="space-between">
                  <Button onClick={onCloseTask}>{t('buttons.cancel')}</Button>
                  <Button
                    type="primary"
                    prefix={<Icon className="action-card__save-button" component={Check} />}
                    onClick={() => {
                      handleCompleteTask(task);
                      setTask(undefined);
                    }}
                  >
                    {t('buttons.save')}
                  </Button>
                </Space>
              </Modal.Footer>
            </Modal>
          ) : (
            <CompleteTask task={task} leadId={task.lead.id} onCancel={onCloseTask} />
          ))}
        <TaskFilter
          count={tasks?.count}
          rightComponents={
            hasEditPermission && (
              <Button type="primary" onClick={handleAdd} prefix={<Icon component={Plus} />}>
                {t('task.addTask')}
              </Button>
            )
          }
        />
        <Card>
          <Loader loading={isLoading} size="regular">
            <Table
              data={tasks?.results}
              columns={columns}
              className="no-border"
              emptyText={t('main.noData')}
            />
          </Loader>
          <Card.Footer>
            <Pagination size={tasks?.total_pages || 0} />
          </Card.Footer>
        </Card>
      </div>
    </OpportunityContextProvider>
  );
};
