import * as React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Charts,
  Customers,
  Folder,
  Funnel,
  Phone,
  Task,
  TaskList,
  UserAlone,
  Map,
  OpportunitiesIcon,
} from 'resources/icons';
import { UserRoleCRM } from 'hooks/useFetcher/interfaces';
import { isCurrentURl } from 'utils';
import { useRolePermission } from './useRolePermission';

export interface Module {
  iconType?: string;
  iconComponent?: React.FC;
  label: string;
  route: string;
  params?: { [key: string]: any };
  active: boolean;
  index?: boolean;
  children?: Modules;
}

interface Modules {
  [key: string]: Module;
}

export const useModules = (): { modules: Modules } => {
  const { t } = useTranslation();

  const modules: Modules = {
    dashboard: {
      iconType: 'home',
      label: t('menu.dashboard'),
      route: '/',
      params: {},
      active: isCurrentURl('/'),
      index: true,
    },

    ...(useRolePermission([
      UserRoleCRM.marketing,
      UserRoleCRM.manager,
      UserRoleCRM.admin,
      UserRoleCRM.expert,
      UserRoleCRM.internal_auditor,
    ]) && {
      contacts: {
        iconComponent: UserAlone,
        label: t('menu.contact'),
        route: '/contacts',
        params: {},
        active: isCurrentURl('/contacts'),
        children: {
          dashboard: {
            label: t('menu.import'),
            route: '/contacts/import',
            params: {},
            active: isCurrentURl('/contacts/import'),
          },
        },
      },
    }),
    ...(useRolePermission([
      UserRoleCRM.marketing,
      UserRoleCRM.manager,
      UserRoleCRM.admin,
      UserRoleCRM.expert,
      UserRoleCRM.internal_auditor,
    ]) && {
      salesFunnel: {
        iconComponent: Funnel,
        label: t('menu.sales_funnel'),
        route: '/sales-funnel',
        params: {},
        active: isCurrentURl('/sales-funnel'),
        children: {
          list: {
            label: t('menu.sales_list'),
            route: '/sales-funnel',
            params: {},
            active: isCurrentURl('/sales-funnel'),
          },
          kanban: {
            label: t('menu.sales_list_kanban'),
            route: '/sales-funnel-kanban',
            params: {},
            active: isCurrentURl('/sales-funnel-kanban'),
          },
        },
      },
    }),
    customers: {
      iconComponent: Customers,
      label: t('menu.customers'),
      route: '/leads',
      params: {},
      active: isCurrentURl('/leads'),
    },
    tasks: {
      iconComponent: Task,
      label: t('menu.tasks'),
      route: '/tasks?completed=all',
      params: {},
      active: isCurrentURl('/tasks'),
    },
    calls: {
      iconComponent: Phone,
      label: t('menu.calls'),
      route: '/calls',
      params: {},
      active: isCurrentURl('/calls'),
    },
    ...(useRolePermission([
      UserRoleCRM.marketing,
      UserRoleCRM.manager,
      UserRoleCRM.admin,
      UserRoleCRM.expert,
      UserRoleCRM.internal_auditor,
      UserRoleCRM.collector,
    ]) && {
      documents: {
        iconComponent: Folder,
        label: t('menu.documents'),
        route: '/documents',
        params: {},
        active: isCurrentURl('/documents'),
      },
    }),
    ...(useRolePermission([
      UserRoleCRM.marketing,
      UserRoleCRM.manager,
      UserRoleCRM.admin,
      UserRoleCRM.expert,
      UserRoleCRM.internal_auditor,
      UserRoleCRM.collector,
    ]) && {
      visits: {
        iconComponent: Map,
        label: t('menu.visits'),
        route: '/visits',
        params: {},
        active: isCurrentURl('/visits'),
      },
    }),
    ...(useRolePermission([
      UserRoleCRM.marketing,
      UserRoleCRM.manager,
      UserRoleCRM.admin,
      UserRoleCRM.internal_auditor,
      UserRoleCRM.soft_collector,
    ]) && {
      reports: {
        iconComponent: TaskList,
        label: t('menu.reports'),
        route: '/report/calls',
        params: {},
        active: isCurrentURl('/report/calls'),
        children: {
          ...(useRolePermission([
            UserRoleCRM.marketing,
            UserRoleCRM.manager,
            UserRoleCRM.admin,
            UserRoleCRM.internal_auditor,
            UserRoleCRM.soft_collector,
          ]) && {
            calls: {
              iconType: 'calls',
              label: t('reports.reportCalls'),
              route: '/report/calls',
              params: {},
              active: isCurrentURl('/report/calls'),
            },
          }),
          ...(useRolePermission([
            UserRoleCRM.marketing,
            UserRoleCRM.manager,
            UserRoleCRM.admin,
            UserRoleCRM.internal_auditor,
          ]) && {
            expenses: {
              iconType: 'nomenclatures',
              label: t('reports.reportExpenses'),
              route: '/report/expenses',
              params: {},
              active: isCurrentURl('/report/expenses'),
            },
          }),
          ...(useRolePermission([
            UserRoleCRM.marketing,
            UserRoleCRM.manager,
            UserRoleCRM.admin,
            UserRoleCRM.internal_auditor,
          ]) && {
            dashboard: {
              iconType: 'agents',
              label: t('reports.reportDashboard'),
              route: '/report/dashboard',
              params: {},
              active: isCurrentURl('/report/dashboard'),
            },
          }),
          ...(useRolePermission([
            UserRoleCRM.marketing,
            UserRoleCRM.manager,
            UserRoleCRM.admin,
            UserRoleCRM.internal_auditor,
          ]) && {
            callsBranches: {
              iconType: 'calls',
              label: t('reports.reportCallsBranches'),
              route: '/report/calls-branches',
              params: {},
              active: isCurrentURl('/report/calls-branches'),
            },
          }),
          ...(useRolePermission([
            UserRoleCRM.marketing,
            UserRoleCRM.manager,
            UserRoleCRM.admin,
            UserRoleCRM.internal_auditor,
          ]) && {
            reportOnline: {
              iconType: 'report-online',
              label: t('reports.reportOnline'),
              route: '/report/online',
              params: {},
              active: isCurrentURl('/report/online'),
            },
          }),
          ...(useRolePermission([
            UserRoleCRM.marketing,
            UserRoleCRM.manager,
            UserRoleCRM.admin,
            UserRoleCRM.internal_auditor,
          ]) && {
            reportOffline: {
              iconType: 'report-offline',
              label: t('reports.reportOffline'),
              route: '/report/offline',
              params: {},
              active: isCurrentURl('/report/offline'),
            },
          }),
          ...(useRolePermission([
            UserRoleCRM.marketing,
            UserRoleCRM.manager,
            UserRoleCRM.admin,
            UserRoleCRM.internal_auditor,
          ]) && {
            reportSalesFunnel: {
              iconType: 'report-sales-funnel',
              label: t('reports.reportSalesFunnel'),
              route: '/report/sales-funnel',
              params: {},
              active: isCurrentURl('/report/sales-funnel'),
            },
          }),
          ...(useRolePermission([
            UserRoleCRM.marketing,
            UserRoleCRM.manager,
            UserRoleCRM.admin,
            UserRoleCRM.internal_auditor,
          ]) && {
            visits: {
              iconType: 'map',
              label: t('reports.reportVisits'),
              route: '/report/visits',
              params: {},
              active: isCurrentURl('/report/visits'),
            },
          }),
          ...(useRolePermission([
            UserRoleCRM.marketing,
            UserRoleCRM.manager,
            UserRoleCRM.admin,
            UserRoleCRM.internal_auditor,
          ]) && {
            opportunities: {
              iconType: 'task',
              label: t('reports.opportunities'),
              route: '/report/opportunities',
              params: {},
              active: isCurrentURl('/report/opportunities'),
            },
          }),
          ...(useRolePermission([
            UserRoleCRM.marketing,
            UserRoleCRM.manager,
            UserRoleCRM.admin,
            UserRoleCRM.internal_auditor,
          ]) && {
            opportunitiesBranches: {
              iconType: 'task',
              label: t('reports.opportunitiesBranches'),
              route: '/report/opportunities-branches',
              params: {},
              active: isCurrentURl('/report/opportunities-branches'),
            },
          }),
          ...(useRolePermission([
            UserRoleCRM.marketing,
            UserRoleCRM.manager,
            UserRoleCRM.admin,
            UserRoleCRM.internal_auditor,
          ]) && {
            opportunitiesBranchesRefusal: {
              iconType: 'task',
              label: t('reports.opportunitiesBranchesRefusal'),
              route: '/report/opportunities-branches-refusal',
              params: {},
              active: isCurrentURl('/report/opportunities-branches-refusal'),
            },
          }),
          ...(useRolePermission([
            UserRoleCRM.marketing,
            UserRoleCRM.manager,
            UserRoleCRM.admin,
            UserRoleCRM.internal_auditor,
          ]) && {
            opportunitiesRefusal: {
              iconType: 'task',
              label: t('reports.opportunitiesRefusal'),
              route: '/report/opportunities-refusal',
              params: {},
              active: isCurrentURl('/report/opportunities-refusal'),
            },
          }),
        },
      },
    }),
    ...(useRolePermission([
      UserRoleCRM.admin,
      UserRoleCRM.internal_auditor,
      UserRoleCRM.manager,
    ]) && {
      admin: {
        iconComponent: Charts,
        label: t('menu.admin'),
        route: useRolePermission([UserRoleCRM.admin, UserRoleCRM.internal_auditor])
          ? '/nomenclatures/lead-source'
          : '/responsible-experts',
        params: {},
        active: isCurrentURl(
          useRolePermission([UserRoleCRM.admin, UserRoleCRM.internal_auditor])
            ? '/nomenclatures/lead-source'
            : '/responsible-experts',
        ),
        children: {
          ...(useRolePermission([UserRoleCRM.admin, UserRoleCRM.internal_auditor]) && {
            nomenclatures: {
              iconType: 'nomenclatures',
              label: t('menu.nomenclatures'),
              route: '/nomenclatures/lead-source',
              params: {},
              active: isCurrentURl('/nomenclatures/lead-source'),
            },
          }),
          ...(useRolePermission([UserRoleCRM.admin, UserRoleCRM.internal_auditor]) && {
            agents: {
              iconType: 'settings',
              label: t('menu.agents'),
              route: '/agents',
              params: {},
              active: isCurrentURl('/agents'),
            },
          }),
          ...(useRolePermission([UserRoleCRM.admin, UserRoleCRM.internal_auditor]) && {
            settings: {
              iconType: 'settings',
              label: t('menu.settings'),
              route: '/settings',
              params: {},
              active: isCurrentURl('/settings'),
            },
          }),
          ...(useRolePermission([
            UserRoleCRM.admin,
            UserRoleCRM.internal_auditor,
            UserRoleCRM.manager,
          ]) && {
            responsibleExpert: {
              iconType: 'userAlone',
              label: t('menu.responsibleExperts'),
              route: '/responsible-experts',
              params: {},
              active: isCurrentURl('/responsible-experts'),
            },
          }),
        },
      },
    }),
    ...(useRolePermission([
      UserRoleCRM.marketing,
      UserRoleCRM.manager,
      UserRoleCRM.admin,
      UserRoleCRM.expert,
      UserRoleCRM.internal_auditor,
    ]) && {
      opportunities: {
        iconComponent: OpportunitiesIcon,
        label: t('reports.opportunities'),
        route: '/opportunities',
        params: {},
        active: isCurrentURl('/opportunities'),
      },
    }),
  };

  return {
    modules,
  };
};
