import * as React from 'react';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useNotify, Loader, Card } from 'ebs-design';
import { useLeadsAPI } from 'api';
import {
  LeadCard,
  TaskCard,
  OpportunityCard,
  ContactCard,
  ContractCard,
  DocumentCard,
  AffiliateContactsCard,
} from './Cards';
import { Lead } from 'types';
import { useRolePermission } from 'hooks/useRolePermission';
import { UserRoleCRM } from 'hooks/useFetcher/interfaces';

export const DetailsCol: React.FC = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const notify = useNotify();
  const { getLeadById } = useLeadsAPI();

  const { data: lead, isLoading } = useQuery<Lead>(['lead', { id }], getLeadById, {
    onError: () => {
      notify.error({ title: t('error.someThingIsWrong') });
    },
  });

  return (
    <div>
      <Loader loading={isLoading}>
        {lead && (
          <>
            <LeadCard lead={lead} />
            <Card className="mt-20" size="small">
              <Card.Body>
                <TaskCard />

                {useRolePermission([
                  UserRoleCRM.marketing,
                  UserRoleCRM.manager,
                  UserRoleCRM.admin,
                  UserRoleCRM.expert,
                  UserRoleCRM.internal_auditor,
                  UserRoleCRM.collector,
                ]) && <OpportunityCard lead={lead} />}

                <ContactCard lead={lead} />

                <ContractCard lead={lead} />

                {useRolePermission([
                  UserRoleCRM.marketing,
                  UserRoleCRM.manager,
                  UserRoleCRM.admin,
                  UserRoleCRM.expert,
                  UserRoleCRM.internal_auditor,
                  UserRoleCRM.collector,
                ]) && <DocumentCard lead={lead} />}

                <AffiliateContactsCard />
              </Card.Body>
            </Card>
          </>
        )}
      </Loader>
    </div>
  );
};
