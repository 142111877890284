import * as React from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useTranslation } from 'react-i18next';
import { Checkbox, Col, Form, Row, useForm, useNotify } from 'ebs-design';
import { SmartSelect } from 'components/organisms';
import { useLeadHandlersAPI, useUsersAPI } from 'api';
import { userTransformResults } from 'utils';
import { ValidationError } from 'errors';
import { LeadHandlersType } from 'types';
import { SessionContext } from 'contexts/SessionContext';
import { useRolePermission } from 'hooks/useRolePermission';
import { UserRoleCRM } from 'hooks/useFetcher/interfaces';

interface Props {
  id?: string | number;
  formId?: string;
  closeModal: () => void;
}

export const ResponsibleExpertForm: React.FC<Props> = ({ formId, id, closeModal }) => {
  const { t } = useTranslation();
  const notify = useNotify();
  const [form] = useForm();
  const queryClient = useQueryClient();
  const { getUsers } = useUsersAPI();
  const { session } = React.useContext(SessionContext);
  const { getLeadById, postLead, patchLead } = useLeadHandlersAPI();

  const isManagerUserRole = useRolePermission([UserRoleCRM.manager]);

  const branchId = session?.data?.user?.teams?.[0]?.id;

  const { data } = useQuery<LeadHandlersType>(
    ['lead-handler', { id, ...(isManagerUserRole && { branch: branchId }) }],
    getLeadById,
    {
      enabled: Boolean(id),
      onError: () => {
        notify.error({ title: t('error.someThingIsWrong') });
      },
    },
  );

  React.useEffect(() => {
    if (data) {
      form.setFieldsValue({
        ...data,
        user: data?.user?.id,
      });
    }
  }, [data]);

  const mutation = useMutation(id ? patchLead : postLead, {
    onError: (e) => {
      if (e instanceof ValidationError) {
        form.setFields(e.fields);
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['responsible-experts']);
      queryClient.invalidateQueries(['lead-handler']);

      closeModal();
      notify.success({ title: t('success.successDataSave') });
    },
  });

  const handleSubmit = (): void => {
    const values = form.getFieldsValue();
    mutation.mutate({ ...(id && { id }), ...values });
  };

  return (
    <Form id={formId} form={form} onFinish={handleSubmit}>
      <Row>
        <Col size={12} className="mt-10">
          <Form.Field name="user" label={t('menu.responsibleExpert')}>
            <SmartSelect
              search
              api={getUsers}
              filters={isManagerUserRole ? { branch: branchId } : undefined}
              mode="single"
              placeholder={t('main.select')}
              transform={userTransformResults}
            />
          </Form.Field>
        </Col>

        <Form.Field
          className="mt-10"
          name="is_active"
          valuePropName="checked"
          label={t('nomenclature.isActive')}
        >
          <Checkbox />
        </Form.Field>

        <Form.Field
          name="is_main_handler"
          valuePropName="checked"
          label={t('nomenclature.mainResponsible')}
        >
          <Checkbox />
        </Form.Field>
      </Row>
    </Form>
  );
};
