import * as React from 'react';

import { useTranslation } from 'react-i18next';
import { AvatarInline, Button, ButtonGroup, Icon, Space, useNotify } from 'ebs-design';
import LogsPanelContext from 'contexts/LogsPanelContext';
import { UserRoleCRM } from 'hooks/useFetcher/interfaces';
import { useRolePermission } from 'hooks/useRolePermission';
import { Play, Trash } from 'resources/icons';
import { dateTimeFormat, format } from 'libs';
import cn from 'classnames';
import { Log, Properties } from 'types';
import { useCallsAPI } from 'api';
import { useMutation, useQueryClient } from 'react-query';
import { AxiosError } from 'axios';

interface ActivityLogCallProps {
  log: Log;
  onDelete: (id) => void;
}

export const ActivityLogCall: React.FC<ActivityLogCallProps> = ({ log, onDelete }) => {
  const { t } = useTranslation();
  const { callItem, openPlayer } = React.useContext(LogsPanelContext);
  const { postCallsOneC } = useCallsAPI();
  const notify = useNotify();
  const queryClient = useQueryClient();

  const { mutate } = useMutation(() => postCallsOneC({ id: log?.call?.id }), {
    onError: (e: AxiosError) => {
      const errorMessage = (e?.response as Properties)?.detail;

      notify.error({ title: errorMessage });
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['lead-logs']);
      notify.success({ title: t('success.successDataSave') });
    },
  });

  const handleOpenPlayer = (): void => {
    if (log.call) {
      openPlayer(log.call);
    }
  };

  const onClickSaveToOneC = (): void => mutate();

  const playButton = (
    <Button
      type="ghost"
      size="small"
      className={cn('activity-log-item__delete', { active: callItem?.id === log?.call?.id })}
      disabled={!log.call?.file}
      onClick={handleOpenPlayer}
      prefix={<Icon className="activity-log-item__delete__icon" component={Play} />}
    />
  );

  return (
    <Space justify="space-between">
      <div className="activity-log-item__content">{log.description}</div>
      <Space className="activity-log-item__right-side">
        <Button type="primary" onClick={onClickSaveToOneC}>
          {t('calls.saveTo1C')}
        </Button>

        <span className="activity-log-item__created-at">
          {format(log.created_at, dateTimeFormat)}
        </span>
        {log.created_by && (
          <AvatarInline
            type="dynamic"
            shortAlt={[
              (log.created_by?.first_name || '')[0],
              (log.created_by?.last_name || '')[0],
            ].join('')}
            alt={[log.created_by?.first_name, log.created_by?.last_name].join(' ') || '---'}
            circle
          />
        )}

        {useRolePermission([UserRoleCRM.admin]) ? (
          <ButtonGroup>
            {playButton}
            <Button
              type="ghost"
              size="small"
              className="activity-log-item__delete"
              onClick={() => onDelete(log.id)}
              prefix={<Icon className="activity-log-item__delete__icon" component={Trash} />}
            />
          </ButtonGroup>
        ) : (
          playButton
        )}
      </Space>
    </Space>
  );
};
