import * as React from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
  Card,
  Loader,
  Table,
  useNotify,
  InputSearch,
  Space,
  Actions,
  Modal,
  Button,
  Icon,
  Label,
} from 'ebs-design';
import { useTranslation } from 'react-i18next';
import { useLeadHandlersAPI } from 'api';
import { Pagination } from 'components/organisms';
import { useStateHandlers } from 'hooks/useStateHandlers';
import { useQueryParams, useQueryUpdate } from 'hooks/useQueryParams';
import { Column, Results, FilterType, LeadHandlersType, LeadActiveStatus } from 'types';
import { defaultFilters } from 'utils';
import { ResponsibleExpertForm } from '../components';
import { ConfirmModal } from 'components/molecules';
import { Plus } from 'resources/icons';

const formId = 'responsible-expert-form';
const queryKey = 'responsible-experts';

export const ResponsibleExperts: React.FC = () => {
  const { t } = useTranslation();
  const { updateQuery } = useQueryUpdate();
  const { getLeadsList, deleteLead } = useLeadHandlersAPI();

  const params = useQueryParams();
  const notify = useNotify();
  const queryClient = useQueryClient();

  const [editItemId, setEditItemId] = React.useState<number>();
  const [deleteItemId, setDeleteItemId] = React.useState();
  const [isOpenAddForm, setIsOpenAddForm] = React.useState(false);

  const [filters, setFilters] = useStateHandlers({ ...defaultFilters });

  const { data, isLoading } = useQuery<Results<LeadHandlersType>>(
    [queryKey, { ...filters, ...params }],
    getLeadsList,
    {
      onError: () => {
        notify.error({ title: t('error.someThingIsWrong') });
      },
    },
  );

  const { mutate } = useMutation(deleteLead, {
    onError: () =>
      notify.error({
        title: t('error.someThingIsWrong'),
      }),
    onSuccess: () => {
      queryClient.invalidateQueries([queryKey]);

      onCloseDeleteModal();
    },
  });

  const handleFilter = (type: FilterType, field: string): FilterType => {
    updateQuery({ ...params, ordering: type ? `${type === 'desc' ? '-' : ''}${field}` : type });

    return type;
  };

  const onSearchUser = (userValue: string): void => setFilters({ search: userValue, page: 1 });

  const onClickAddExpert = (): void => setIsOpenAddForm(true);

  const onCloseDeleteModal = (): void => setDeleteItemId(undefined);

  const onCloseExpertModal = (): void => {
    setIsOpenAddForm(false);
    setEditItemId(undefined);
  };

  const onDelete = (): void => {
    if (deleteItemId) {
      mutate(deleteItemId);
    }
  };

  const columns: Column<LeadHandlersType>[] = React.useMemo(
    () => [
      {
        title: t('menu.responsibleExpert'),
        onFilter: (type) => handleFilter(type, 'name'),
        dataIndex: ['user', 'name'],
      },
      {
        title: t('lead.status'),
        onFilter: (type) => handleFilter(type, 'is_active'),
        dataIndex: 'is_active',
        render: (isActive) => (
          <Label
            className="calls__type-label"
            status={LeadActiveStatus[isActive]}
            text={isActive ? t('nomenclature.isActive') : t('nomenclature.notActive')}
          />
        ),
      },
      {
        title: t('nomenclature.mainResponsible'),
        onFilter: (type) => handleFilter(type, 'is_main_handler'),
        dataIndex: 'is_main_handler',
        render: (isMainHandler) => (
          <Label
            className="calls__type-label"
            status={LeadActiveStatus[isMainHandler]}
            text={isMainHandler ? t('calls.answeredTrue') : t('calls.answeredFalse')}
          />
        ),
      },
      {
        align: 'right',
        render: ({ id }) => (
          <Space justify="end">
            <Actions placement="bottom">
              <Actions.Item onClick={() => setEditItemId(id)}>
                {t('nomenclature.edit')}
              </Actions.Item>

              <Actions.Item onClick={() => setDeleteItemId(id)}>
                {t('nomenclature.delete')}
              </Actions.Item>
            </Actions>
          </Space>
        ),
      },
    ],
    [t, data, params],
  );

  return (
    <>
      <Space className="mb-15" justify="space-between">
        <Space>
          <h3 className="title">{`${t(`menu.responsibleExperts`)} (${data?.count || 0})`}</h3>

          <InputSearch placeholder={t('filter.search')} onSearch={onSearchUser} />
        </Space>

        <Button type="primary" prefix={<Icon component={Plus} />} onClick={onClickAddExpert}>
          {t('buttons.addNew')}
        </Button>
      </Space>

      <Card>
        <Loader loading={isLoading} size="regular">
          <Table
            emptyText={t('main.noData')}
            data={data?.results || []}
            columns={columns}
            className="no-border"
          />
        </Loader>
        <Card.Footer>
          <Pagination size={data?.total_pages || 0} />
        </Card.Footer>
      </Card>

      {(isOpenAddForm || editItemId) && (
        <Modal
          closeOnClickOutside={false}
          open
          header={t('menu.responsibleExpert')}
          onClose={onCloseExpertModal}
        >
          <Modal.Content>
            <ResponsibleExpertForm
              formId={formId}
              id={editItemId}
              closeModal={onCloseExpertModal}
            />
          </Modal.Content>

          <Modal.Footer>
            <Space justify="space-between">
              <Button onClick={onCloseExpertModal}>{t('buttons.cancel')}</Button>

              <Button type="primary" submit form={formId}>
                {t('buttons.save')}
              </Button>
            </Space>
          </Modal.Footer>
        </Modal>
      )}
      {deleteItemId && <ConfirmModal onSuccess={onDelete} onCancel={onCloseDeleteModal} />}
    </>
  );
};
