import { UserRoleCRM } from 'hooks/useFetcher/interfaces';
import {
  BarChart,
  Call,
  Funnel,
  Map,
  OpportunitiesIcon,
  ReportsChart,
  TableView,
} from 'resources/icons';

export const reportsRoutes = [
  {
    icon: Call,
    label: 'reportMainCalls',
    permissions: [
      UserRoleCRM.marketing,
      UserRoleCRM.manager,
      UserRoleCRM.admin,
      UserRoleCRM.internal_auditor,
      UserRoleCRM.soft_collector,
    ],
    children: [
      {
        path: '/report/calls',
        label: 'reportCalls',
        permissions: [
          UserRoleCRM.marketing,
          UserRoleCRM.manager,
          UserRoleCRM.admin,
          UserRoleCRM.internal_auditor,
          UserRoleCRM.soft_collector,
        ],
      },
      {
        path: '/report/calls-branches',
        label: 'reportCallsBranches',
        permissions: [
          UserRoleCRM.marketing,
          UserRoleCRM.manager,
          UserRoleCRM.admin,
          UserRoleCRM.internal_auditor,
        ],
      },
    ],
  },
  {
    icon: TableView,
    path: '/report/expenses',
    label: 'reportExpenses',
    permissions: [
      UserRoleCRM.marketing,
      UserRoleCRM.manager,
      UserRoleCRM.admin,
      UserRoleCRM.internal_auditor,
    ],
  },
  {
    icon: BarChart,
    path: '/report/dashboard',
    label: 'reportDashboard',
    permissions: [
      UserRoleCRM.marketing,
      UserRoleCRM.manager,
      UserRoleCRM.admin,
      UserRoleCRM.internal_auditor,
    ],
  },
  {
    icon: ReportsChart,
    label: 'reportMainOnline',
    permissions: [
      UserRoleCRM.marketing,
      UserRoleCRM.manager,
      UserRoleCRM.admin,
      UserRoleCRM.internal_auditor,
    ],
    children: [
      {
        path: '/report/online',
        label: 'reportOnline',
        permissions: [
          UserRoleCRM.marketing,
          UserRoleCRM.manager,
          UserRoleCRM.admin,
          UserRoleCRM.internal_auditor,
        ],
      },
      {
        path: '/report/offline',
        label: 'reportOffline',
        permissions: [
          UserRoleCRM.marketing,
          UserRoleCRM.manager,
          UserRoleCRM.admin,
          UserRoleCRM.internal_auditor,
        ],
      },
    ],
  },
  {
    icon: Funnel,
    path: '/report/sales-funnel',
    label: 'reportSalesFunnel',
    permissions: [
      UserRoleCRM.marketing,
      UserRoleCRM.manager,
      UserRoleCRM.admin,
      UserRoleCRM.internal_auditor,
    ],
  },
  {
    icon: Map,
    path: '/report/visits',
    label: 'reportVisits',
    permissions: [
      UserRoleCRM.marketing,
      UserRoleCRM.manager,
      UserRoleCRM.admin,
      UserRoleCRM.internal_auditor,
    ],
  },
  {
    icon: OpportunitiesIcon,
    label: 'mainOpportunities',
    permissions: [
      UserRoleCRM.marketing,
      UserRoleCRM.manager,
      UserRoleCRM.admin,
      UserRoleCRM.internal_auditor,
    ],
    children: [
      {
        path: '/report/opportunities',
        label: 'opportunities',
        permissions: [
          UserRoleCRM.marketing,
          UserRoleCRM.manager,
          UserRoleCRM.admin,
          UserRoleCRM.internal_auditor,
        ],
      },
      {
        path: '/report/opportunities-branches',
        label: 'opportunitiesBranches',
        permissions: [
          UserRoleCRM.marketing,
          UserRoleCRM.manager,
          UserRoleCRM.admin,
          UserRoleCRM.internal_auditor,
        ],
      },
      {
        path: '/report/opportunities-branches-refusal',
        label: 'opportunitiesBranchesRefusal',
        permissions: [
          UserRoleCRM.marketing,
          UserRoleCRM.manager,
          UserRoleCRM.admin,
          UserRoleCRM.internal_auditor,
        ],
      },
      {
        path: '/report/opportunities-refusal',
        label: 'opportunitiesRefusal',
        permissions: [
          UserRoleCRM.marketing,
          UserRoleCRM.manager,
          UserRoleCRM.admin,
          UserRoleCRM.internal_auditor,
        ],
      },
    ],
  },
];
