import * as React from 'react';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import { Icon, Select, Loader } from 'ebs-design';
import { useSetState } from 'react-use';
import { Filters } from 'components/molecules';
import { Map, UserAlone } from 'resources/icons';
import { useQueryParams, useQueryUpdate } from 'hooks/useQueryParams';
import { useRolePermission } from 'hooks/useRolePermission';
import { UserRoleCRM } from 'hooks/useFetcher/interfaces';
import { useBranchesAPI, useUsersAPI } from 'api';
import { defaultFilters, defaultNonPagination, formatSelectValueToNumber } from 'utils';
import { Results, List, User } from 'types';

interface FiltersProps {
  branch?: string;
  date?: string;
  date_from?: string;
  date_to?: string;
}

interface ReportFilterProps {
  areCallBranches?: boolean;
}

export const ReportCallsFilter: React.FC<ReportFilterProps> = ({ areCallBranches }) => {
  const { t } = useTranslation();
  const params = useQueryParams();
  const { updateQuery } = useQueryUpdate();
  const { getUsers } = useUsersAPI();
  const { getBranchesMarketingReportList, getBranches } = useBranchesAPI();

  const [branchesFilter, setBranchesFilter] = useSetState(defaultFilters);
  const [usersFilter, setUsersFilter] = useSetState(defaultNonPagination);

  const { data: users } = useQuery<Results<User>>(['users', { ...usersFilter }], getUsers);

  const { data: branches, isLoading } = useQuery<Results<List>>(
    ['branches', { ...defaultFilters, ...branchesFilter }],
    useRolePermission([UserRoleCRM.marketing]) ? getBranchesMarketingReportList : getBranches,
  );

  const [filters, setFilters] = useSetState<FiltersProps>(params);

  React.useEffect(() => {
    updateQuery(filters);
  }, [filters, updateQuery]);

  const handleFilters = (value): void => {
    if (value?.date) {
      const [date_from, date_to] = value?.date;

      if (date_from && date_to) {
        setFilters({ date_from, date_to });
      }
      return;
    }
    if (value?.date === null) {
      setFilters({
        date_from: undefined,
        date_to: undefined,
      });
      return;
    }
    setFilters(value);
  };

  return (
    <Filters onChange={handleFilters}>
      <Filters.Select
        prefix={<Icon component={UserAlone} />}
        placeholder={t('filter.user')}
        value={params.user}
        field="user"
      >
        <Select.Options>
          {(users?.results || []).map((item, key) => (
            <Select.Options.Item key={`${key}-user`} value={item.id}>
              {item?.first_name || item?.last_name
                ? `${item?.first_name} ${item?.last_name}`
                : item.email}
            </Select.Options.Item>
          ))}
        </Select.Options>
        <Select.Pagination
          count={users?.count || 0}
          limit={usersFilter.per_page}
          page={usersFilter.page}
          setPage={(page) => setUsersFilter({ page })}
          mode="scroll"
        />
      </Filters.Select>

      <Filters.DatePickerFilter
        value={[filters.date_from, filters.date_to]}
        field="date"
        type="range"
        placeholder={t('reports.sortByDate')}
      />

      {!areCallBranches && (
        <Filters.Select
          prefix={<Icon component={Map} />}
          placeholder={t('filter.branch')}
          value={formatSelectValueToNumber(params.branch)}
          field="branch"
        >
          <Select.Options>
            {(branches?.results || []).map((item, key) => (
              <Select.Options.Item key={`${key}-branch`} value={item.key}>
                {item.label}
              </Select.Options.Item>
            ))}
          </Select.Options>
          <Select.Pagination
            count={branches?.count || 0}
            limit={branchesFilter.per_page}
            page={branchesFilter.page}
            setPage={(page) => setBranchesFilter({ page })}
            mode="scroll"
          />

          {isLoading && <Loader.Inline />}
        </Filters.Select>
      )}
    </Filters>
  );
};
