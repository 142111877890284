import useFetcher from 'hooks/useFetcher/useFetcher';
import { ServiceTypes } from 'hooks/useFetcher/interfaces';
import { stringifyUrl, getRequest } from 'utils';
import { Properties } from 'types';

export const useCallsAPI = (): Properties => {
  const fetcher = useFetcher(ServiceTypes.CRM);

  return {
    getCalls: ({ queryKey: [, query] }) => {
      return fetcher(stringifyUrl(`/calls`, query), getRequest);
    },

    postCallsOneC: ({ id }) => {
      return fetcher(`/calls/${id}/one-c`, {
        request: {
          method: 'POST',
        },
      });
    },
  };
};
